@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* Muller - Regular */
  @font-face {
    font-family: Muller;
    font-weight: 400;
    src: url("assets/fonts/Muller-Regular.woff2") format("woff2"),
      url("assets/fonts/Muller-Regular.woff") format("woff"),
      url("assets/fonts/Muller-Regular.ttf") format("truetype"),
      url("assets/fonts/Muller-Regular.otf") format("opentype");
    font-display: swap;
  }

  /* Muller - Medium */
  @font-face {
    font-family: Muller;
    font-weight: 500;
    src: url("assets/fonts/Muller-Medium.woff2") format("woff2"),
      url("assets/fonts/Muller-Medium.woff") format("woff"),
      url("assets/fonts/Muller-Medium.ttf") format("truetype"),
      url("assets/fonts/Muller-Medium.otf") format("opentype");
    font-display: swap;
  }

  /* Monument - Regular */
  @font-face {
    font-family: Monument;
    font-weight: 400;
    src: url("assets/fonts/PPMonumentExtended-Regular.woff2") format("woff2"),
      url("assets/fonts/PPMonumentExtended-Regular.woff") format("woff"),
      url("assets/fonts/PPMonumentExtended-Regular.ttf") format("truetype"),
      url("assets/fonts/PPMonumentExtended-Regular.otf") format("opentype");
    font-display: swap;
  }

  /* Monument - Medium */
  @font-face {
    font-family: Monument;
    font-weight: 500;
    src: url("assets/fonts/PPMonumentExtended-Medium.woff2") format("woff2"),
      url("assets/fonts/PPMonumentExtended-Medium.woff") format("woff"),
      url("assets/fonts/PPMonumentExtended-Medium.ttf") format("truetype"),
      url("assets/fonts/PPMonumentExtended-Medium.otf") format("opentype");
    font-display: swap;
  }

  /* Monument - Bold */
  @font-face {
    font-family: Monument;
    font-weight: 700;
    src: url("assets/fonts/PPMonumentExtended-Bold.woff2") format("woff2"),
      url("assets/fonts/PPMonumentExtended-Bold.woff") format("woff"),
      url("assets/fonts/PPMonumentExtended-Bold.ttf") format("truetype"),
      url("assets/fonts/PPMonumentExtended-Bold.otf") format("opentype");
    font-display: swap;
  }

  body {
    margin: 0;
    font-family: Muller, Arial, Helvetica, sans-serif;
    color: #101010;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.input {
  @apply h-12 w-full border border-grey rounded-md text-black placeholder:text-black/40 text-sm leading-[14px] xs:text-base
  hover:shadow-orange hover:border-transparent focus-visible:border focus-visible:border-[#FA4717] focus-visible:outline-0
  focus-visible:shadow-orange disabled:hover:shadow-none transition outline-none;
}

.input-label {
  @apply text-sm leading-4 text-black/70;
}

.input-error-message {
  @apply text-[#E9344B] text-xs mt-1;
}

.btn-contained {
  @apply bg-primary rounded-full text-white px-8 w-full font-medium
  hover:bg-[#FF6B43] active:bg-[#FF6B43] focus-visible:bg-[#FF6B43] focus-visible:outline-white
  focus-visible:outline-offset-[-3px] focus-visible:outline
  disabled:bg-[#F6F6F6] disabled:text-black/40 transition h-12 text-sm;
}

.btn-outlined {
  @apply bg-white rounded-full text-black px-4 sm:px-7 w-full max-w-full font-medium border border-[#CACACA]
  enabled:hover:border-black hover:bg-[#F8F8F8] enabled:active:border-black focus-visible:bg-[#F8F8F8] focus-visible:outline-0
  disabled:text-black/40 transition h-12 text-sm;
}

#root {
  @apply flex;
}